import React from 'react'

const SpeechRecognition      = window.SpeechRecognition || window.webkitSpeechRecognition;
const SpeechGrammarList      = window.SpeechGrammarList || window.webkitSpeechGrammarList;
const SpeechRecognitionEvent = window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;

export default function SpeechGame() {
    return (
        <>

        </>
    );
}