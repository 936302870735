import React, { useState } from 'react'

// mui components
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'

// for themes
import { darkTheme, lightTheme } from '../common/themes'
import { ThemeProvider } from '@mui/material/styles'

// css
import './Home.css'

import { getCookieValue } from '../Game/util'

// an array with all the supported languages
const SUPPORTED_LANGUAGES = [
    "Arabic",
    "Hindi",
    "Portuguese",
    "Swedish",
    "Turkish",
    "Danish",
    "English",
    "French",
    "German",
    "Italian",
    "Japanese",
    "Polish",
    "Russian",
    "Spanish",
].sort();

const SUPPORTED_DIFFICULTIES = ["Easy", "Medium", "Hard", "Very Hard"];

const menuProps = {
    PaperProps: {
        style: {
            maxHeight: 250,
        }
    }
}

// for converting difficulty names to their respective numbers
function convertDifficultyToString(string) {
    if (string == 1) {
        return "Easy";
    } else if (string == 2) {
        return "Medium";
    } else if (string == 3) {
        return "Hard";
    } else if (string == 4) {
        return "Very Hard";
    }
}

function SelectMenu({ label, items, setFunction, defaultValue }) {
    const [selectedValue, setSelectedValue] = useState(defaultValue);

    function handleChange(event) {
        // for converting difficulty names to their respective numbers
        if (event.target.value == "Easy") {
            setFunction(1);
        } else if (event.target.value == "Medium") {
            setFunction(2);
        } else if (event.target.value == "Hard") {
            setFunction(3);
        } else if (event.target.value == "Very Hard") {
            setFunction(4);
        } else {
            setFunction(event.target.value);
        }

        setSelectedValue(event.target.value);
    }

    return (
        <>
            <FormControl id="start-game-widgets">
                <InputLabel>{label}</InputLabel>
                <Select
                    value={selectedValue}
                    label={label}
                    onChange={handleChange}
                    MenuProps={menuProps}
                >
                    {items.map((value) => (
                        <MenuItem key={value} value={value}>
                            {value}
                        </MenuItem> 
                    )
                    )}
                </Select>
            </FormControl>
        </>
    );
}

export default function Home({ theme }) {
    const [redirectLanguage, setRedirectLanguage]     = useState(getCookieValue("selectedListenLanguage"));
    const [redirectDifficulty, setRedirectDifficulty] = useState(getCookieValue("selectedListenDifficulty"));

    function handleStartGameClick() {
        document.cookie = "selectedListenLanguage=" + redirectLanguage + "; expires=Tues, 1 Jan 2030 12:00:00 UTC; path=" + window.location.pathname;
        document.cookie = "selectedListenDifficulty=" + redirectDifficulty + "; expires=Tues, 1 Jan 2030 12:00:00 UTC; path=" + window.location.pathname;

        if (redirectLanguage && redirectDifficulty > 0) {
            window.location.href='/game/' + redirectLanguage + "/" + redirectDifficulty;
        }
    }

	return (
		<ThemeProvider theme={theme.theme === "light" ? lightTheme : darkTheme}>
			<h1 className="changes-theme" id="header">Lingua Audire</h1>
            <div className="changes-theme" id="subheader-container">
			    <h3 id="subheader">Designed to improve language listening skills, Lingua Audire provides a way for users to audibly input in their target language.</h3>
            </div>

            <Stack id="start-game-stack" direction="row" spacing={5}>
                <SelectMenu items={SUPPORTED_LANGUAGES} setFunction={setRedirectLanguage} defaultValue={getCookieValue("selectedListenLanguage")} label="Language"/>
                <SelectMenu items={SUPPORTED_DIFFICULTIES} setFunction={setRedirectDifficulty} defaultValue={convertDifficultyToString(getCookieValue("selectedListenDifficulty"))} label="Difficulty"/>
                <Button variant="contained" onClick={handleStartGameClick}>Start Game</Button>
            </Stack>
		</ThemeProvider>
	);
};